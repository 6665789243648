<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入歌曲名/歌手"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_handle"
        placeholder="处理状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="已处理" :value="1" />
        <el-option label="未处理" :value="0" />
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
      <!-- <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="out"
          :before-generate="startPost"
          :before-finish="endPost"
          :header="'用户信息'"
          type="xls"
          worksheet="用户信息"
          name="用户信息.xls"
        >
          <el-button type="success"> 导出用户信息 </el-button>
        </download-excel>
      </span> -->
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="用户名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.nickname }}
        </template>
      </el-table-column>
      <el-table-column label="用户头像" min-width="110" align="center">
        <template slot-scope="scope">
          <img
            v-if="scope.row.user.avatar"
            :src="scope.row.user.avatar"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="歌曲名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.music_score_name }}
        </template>
      </el-table-column>
      <el-table-column label="歌手" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.author }}
        </template>
      </el-table-column>
      <el-table-column label="提交时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="处理状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_handle == 1" type="success">已处理</el-tag>
          <el-tag v-else type="danger">未处理</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_handle == 0"
            size="mini"
            type="danger"
            @click="handleForbid(scope.row.id, '处理')"
          >
            处理
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
export default {
  components: { downloadExcel },
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        is_handle: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/musicScore/feedbackLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    startPost() {
      this.loading = true;
    },
    endPost() {
      this.loading = false;
    },
    handleForbid(val, text) {
      this.$confirm("此操作将" + text + "该扒谱, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/musicScore/feedbackHandle",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
